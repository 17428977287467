import { type ClassValue, clsx } from "clsx"
import { DateTime } from "luxon"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getDateObjectFromISOString = (isoString: string | null | undefined) => {
  if (isoString) {
    return DateTime.fromISO(isoString.slice(0, -1)).toJSDate();
  } else {
    return null;
  }
}