const projectEventsType = {
  onSaveInformation: 'onSaveInformation',
  onSaveUserData: 'onSaveUserData',
  onSaveRequirements: 'onSaveRequirements',
  onAddComponent: 'onAddComponent',
  onRemoveComponent: 'onRemoveComponent',
  onAddMaterial: 'onAddMaterial',
  onRemoveMaterial: 'onRemoveMaterial',
  onSetMeasures: 'onSetMeasures',
  onSetNotes: 'onSetNotes',
  onSetNumberPieces: 'onSetNumberPieces',
  onSetMediaComponent: 'onSetMediaComponent',
  onSetMediaAssemble: 'onSetMediaAssemble',
  onSetProjectId: 'onSetProjectId',
  onSetProject: 'onSetProject',
  onAddAssemble: 'onAddAssemble',
  onRemoveAssemble: 'onRemoveAssemble',
  onAssembleFeature: 'onAssembleFeature',
  setOrderId: 'setOrderId',
  onSetHasSample: 'onSetHasSample',
  onEdit: 'onEdit',
  onSetSampleQuantity: 'onSetSampleQuantity',
  onSetAllHasSample: 'onSetAllHasSample'
}

const initialStore = {
  _id: '',
  orderId: null,
  information: {
    projectName: '',
    category: { label: '', id: '' },
    productType: null,
    startDate: '',
    startAmount: '',
    baseBudget: null,
    limitBudget: null,
    maxAmount: null,
    limitDate: null,
  },
  development: {
    userData: {
      minAge: null,
      maxAge: null,
      gender: null,
      nationalities: [],
      favoriteBrands: [],
      keyRequirements: [],
    },
    requirements: {
      use: '',
      function: '',
      comments: ''
    },
  },
  manufactory: {
    features: {
      components: [],
      assemblies: [],
      hasAssemblies: false
    }
  },
}

const projectStoreReducer = (state: $TSFixMe, action: $TSFixMe) => {
  switch (action.type) {
    case projectEventsType.onSaveInformation:
      return {
        ...state,
        information: action.payload
      }
    case projectEventsType.onSaveUserData:
      return {
        ...state,
        development: {
          ...state.development,
          userData: action.payload
        }
      }
    case projectEventsType.onSaveRequirements:
      return {
        ...state,
        development: {
          ...state.development,
          requirements: action.payload
        }
      }
    case projectEventsType.onAddComponent:
      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            components: [...state.manufactory.features.components, action.payload]
          }
        }
      }
    case projectEventsType.onRemoveComponent:
      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            components: action.payload
          }
        }
      }
    case projectEventsType.onAddMaterial: {
      const components = state.manufactory.features.components.map((item: $TSFixMe) => {
        if (item._id === action.payload.componentId) {
          if (!item.materials.find((material: $TSFixMe) => material._id === action.payload.material._id)) {
            if (action.payload.material.isSpecific) {
              return {
                ...item,
                materials: [
                  action.payload.material
                ]
              }
            } else {
              if (!item.materials.some((i: $TSFixMe) => i.category._id === action.payload.material.category._id && i.isSpecific === true)) {
                return {
                  ...item,
                  materials: [...item.materials, action.payload.material]
                }
              } else {
                return item
              }
            }
          } else {
            return item;
          }
        } else {
          return item;
        }
      });

      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            components: components
          }
        }
      }
    }
    case projectEventsType.onRemoveMaterial: {
      const components = state.manufactory.features.components.map((item: $TSFixMe) => {
        if (item._id === action.payload.componentId) {
          return {
            ...item,
            materials: item.materials.filter((item: $TSFixMe) => item._id !== action.payload.material._id)
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            components: components
          }
        }
      }
    }
    case projectEventsType.onSetMeasures: {
      if (action.payload.type === 'COMPONENT') {
        const components = state.manufactory.features.components.map((item: $TSFixMe) => {
          if (item._id === action.payload.currentItemId) {
            return {
              ...item,
              measures: action.payload.measures
            }
          } else {
            return item;
          }
        });

        return {
          ...state,
          manufactory: {
            ...state.manufactory,
            features: {
              ...state.manufactory.features,
              components: components
            }
          },
        }
      } else if (action.payload.type === 'ASSEMBLE') {
        const assemblies = state.manufactory.features.assemblies.map((item: $TSFixMe) => {
          if (item._id === action.payload.currentItemId) {
            return {
              ...item,
              measures: action.payload.measures
            }
          } else {
            return item;
          }
        });

        return {
          ...state,
          manufactory: {
            ...state.manufactory,
            features: {
              ...state.manufactory.features,
              assemblies: assemblies
            }
          },
        }
      } else {
        break;
      }
    }
    case projectEventsType.onSetNotes: {
      if (action.payload.type === 'COMPONENT') {
        const components = state.manufactory.features.components.map((item: $TSFixMe) => {
          if (item._id === action.payload.currentItemId) {
            return {
              ...item,
              notes: action.payload.notes
            }
          } else {
            return item;
          }
        });

        return {
          ...state,
          manufactory: {
            ...state.manufactory,
            features: {
              ...state.manufactory.features,
              components: components
            }
          },
        }
      } else if (action.payload.type === 'ASSEMBLE') {
        const assemblies = state.manufactory.features.assemblies.map((item: $TSFixMe) => {
          if (item._id === action.payload.currentItemId) {
            return {
              ...item,
              notes: action.payload.notes
            }
          } else {
            return item;
          }
        });

        return {
          ...state,
          manufactory: {
            ...state.manufactory,
            features: {
              ...state.manufactory.features,
              assemblies: assemblies
            }
          },
        }
      } else {
        break;
      }
    }
    case projectEventsType.onSetNumberPieces: {
      if (action.payload.type === 'COMPONENT') {
        const components = state.manufactory.features.components.map((item: $TSFixMe) => {
          if (item._id === action.payload.currentItemId) {
            return {
              ...item,
              numberPieces: action.payload.numberPieces
            }
          } else {
            return item;
          }
        });

        return {
          ...state,
          manufactory: {
            ...state.manufactory,
            features: {
              ...state.manufactory.features,
              components: components
            }
          },
        }
      } else if (action.payload.type === 'ASSEMBLE') {
        const assemblies = state.manufactory.features.assemblies.map((item: $TSFixMe) => {
          if (item._id === action.payload.currentItemId) {
            return {
              ...item,
              numberPieces: action.payload.numberPieces
            }
          } else {
            return item;
          }
        });

        return {
          ...state,
          manufactory: {
            ...state.manufactory,
            features: {
              ...state.manufactory.features,
              assemblies: assemblies
            }
          },
        }
      } else {
        break;
      }
    }
    case projectEventsType.onSetMediaComponent: {
      const components = state.manufactory.features.components.map((item: $TSFixMe) => {
        if (item._id === action.payload.componentId) {
          return {
            ...item,
            media: action.payload.media
          }
        } else {
          return item;
        }
      });

      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            components: components
          }
        }
      }
    }
    case projectEventsType.onSetMediaAssemble: {
      const assemblies = state.manufactory.features.assemblies.map((item: $TSFixMe) => {
        if (item._id === action.payload.assembleId) {
          return {
            ...item,
            media: action.payload.media
          }
        } else {
          return item;
        }
      });

      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            assemblies: assemblies
          }
        },
      }
    }
    case projectEventsType.onSetProjectId: {
      return {
        ...state,
        _id: action.payload._id
      }
    }
    case projectEventsType.onSetProject: {
      return {
        ...action.payload.project,
        information: {
          ...action.payload.project.information,
          startDate: action.payload.project.information.startDate,
          limitDate: action.payload.project.information.limitDate
        },
        development: action.payload.project.development ? action.payload.project.development : initialStore.development,
        manufactory: action.payload.project.manufactory ? action.payload.project.manufactory : initialStore.manufactory
      }
    }
    case projectEventsType.onAddAssemble:
      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            assemblies: [...state.manufactory.features.assemblies, action.payload]
          }
        },
      }
    case projectEventsType.onRemoveAssemble:
      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            assemblies: action.payload
          }
        },
      }
    case projectEventsType.onAssembleFeature:
      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            hasAssemblies: action.payload
          }
        },
      }
    case projectEventsType.setOrderId:
      return {
        ...state,
        orderId: action.payload.orderId
      }
    case projectEventsType.onEdit:
      return {
        ...state,
        isEdit: action.payload
      }
    case projectEventsType.onSetHasSample: {
      const components = state.manufactory.features.components.map((item: $TSFixMe) => {
        if (item._id === action.payload.componentId) {
          return {
            ...item,
            hasSample: action.payload.hasSample,
            sampleQuantity: action.payload.hasSample ? 1 : null
          }
        } else {
          return item;
        }
      });

      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            components: components
          }
        },
      }
    }
    case projectEventsType.onSetSampleQuantity: {
      const components = state.manufactory.features.components.map((item: $TSFixMe) => {
        if (item._id === action.payload.componentId) {
          return {
            ...item,
            sampleQuantity: action.payload.sampleQuantity
          }
        } else {
          return item;
        }
      });

      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            components: components
          }
        },
      }
    }
    case projectEventsType.onSetAllHasSample: {
      const components = state.manufactory.features.components.map((item: $TSFixMe) => {
        return {
          ...item,
          hasSample: true,
          sampleQuantity: 1
        }
      });

      return {
        ...state,
        manufactory: {
          ...state.manufactory,
          features: {
            ...state.manufactory.features,
            components: components
          }
        },
      }
    }
    default:
      return state;
  }
}

export { initialStore }
export { projectEventsType }
export default projectStoreReducer;