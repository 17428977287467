import '../styles/globals.css'
import { SnackbarProvider } from 'notistack';
import { Provider } from 'next-auth/client';
import { useEffect } from 'react';
import { useRouter } from 'next/dist/client/router';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'npro... Remove this comment to see the full error message
import nProgress from 'nprogress';
import '../styles/nprogress.css';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../apollo/client';
import { ProfileStoreProvider } from '../store';
import Script from 'next/script'
import { hotjar } from 'react-hotjar';

function MyApp({
  Component,
  pageProps
}: $TSFixMe) {
  const router = useRouter();
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    const handleStart = () => {
      nProgress.start();
    }

    const handleStop = () => {
      nProgress.done();
    }

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    }
  }, [router]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_HJID) {
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      hotjar.initialize(process.env.NEXT_PUBLIC_HJID, process.env.NEXT_PUBLIC_HJSV);
    }
  }, [])

  return (
    <ApolloProvider client={apolloClient}>
      <Provider>
        <SnackbarProvider
          maxSnack={2}>
          <ProfileStoreProvider>
            <Script
              id="gtag-base"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
                  `,
              }}
            />
            <Component {...pageProps} />
          </ProfileStoreProvider>
        </SnackbarProvider>
      </Provider>
    </ApolloProvider>
  )
}

export default MyApp
