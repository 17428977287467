const processesAndMaterialsEventTypes = {
  onSelectCategory: 'onSelectCategory',
  onSetCateogryImage: 'onSetCategoryImage',
  onAddProcess: 'onAddProcess',
  onAddMaterialToProcesses: 'onAddMaterialToProcesses',
  onRemoveProcess: 'onRemoveProcess',
  onRemoveMaterialFromProcesses: 'onRemoveMaterialFromProcesses',
  onAddProcesses: 'onAddProcesses',
  onSetProcessToAddMaterials: 'onSetProcessToAddMaterials',
  onRemoveProcessToAddMaterials: 'onRemoveProcessToAddMaterials',
  onSyncMaterialsToProcesses: 'onSyncMaterialsToProcesses',
  onResetState: 'onResetState',
  onSelectAllProcessesToAddMaterials: 'onSelectAllProcessesToAddMaterials'
}

const initialStore = {
  category: '',
  categoryImage: '',
  processes: [],
  processesToAddMaterials: [],
}

const processesAndMaterialsStoreReducer = (state: $TSFixMe, action: $TSFixMe) => {
  switch (action.type) {
    case processesAndMaterialsEventTypes.onSelectCategory:
      return {
        ...state,
        category: action.payload
      }
    case processesAndMaterialsEventTypes.onSetCateogryImage:
      return {
        ...state,
        categoryImage: action.payload
      }
    case processesAndMaterialsEventTypes.onAddProcess:
      return {
        ...state,
        processes: [{ ...action.payload.process, materials: [], category: state.category, categoryImage: state.categoryImage, errors: null }, ...state.processes]
      }
    case processesAndMaterialsEventTypes.onRemoveProcess:
      return {
        ...state,
        processes: state.processes.filter((item: $TSFixMe) => item._id !== action.payload.process._id)
      };
    case processesAndMaterialsEventTypes.onAddMaterialToProcesses: {
      const processesWithMaterials = state.processes.map((item: $TSFixMe) => {
        const isProcessInProcessesToAddMaterialsList = state.processesToAddMaterials.find((process: $TSFixMe) => process._id === item._id);

        if (isProcessInProcessesToAddMaterialsList) {
          return {
            ...item,
            materials: [...item.materials, action.payload.material]
          }
        } else {
          return item;
        }
      });

      return {
        ...state,
        processes: processesWithMaterials
      }
    }
    case processesAndMaterialsEventTypes.onRemoveMaterialFromProcesses: {
      const selectedMaterialsFiltered = state.processes.map((item: $TSFixMe) => {
        const isProcessInProcessesToAddMaterialsList = state.processesToAddMaterials.find((process: $TSFixMe) => process._id === item._id);

        if (isProcessInProcessesToAddMaterialsList) {
          return {
            ...item,
            materials: item['materials'].filter((material: $TSFixMe) => material._id !== action.payload.material._id)
          };
        } else {
          return item
        }
      });

      return {
        ...state,
        processes: selectedMaterialsFiltered
      }
    }
    case processesAndMaterialsEventTypes.onAddProcesses:
      return {
        ...state,
        processes: action.payload.processes
      }
    case processesAndMaterialsEventTypes.onSetProcessToAddMaterials:
      return {
        ...state,
        processesToAddMaterials: action.payload.processesToAddMaterials
      }
    case processesAndMaterialsEventTypes.onRemoveProcessToAddMaterials:
      return {
        ...state,
        processesToAddMaterials: state.processesToAddMaterials.filter((item: $TSFixMe) => item._id !== action.payload.process._id)
      };
    case processesAndMaterialsEventTypes.onSyncMaterialsToProcesses: {
      const processesSyncMaterials = state.processes.map((item: $TSFixMe) => {
        const isProcessInProcessesToAddMaterialsList = state.processesToAddMaterials.find((process: $TSFixMe) => process._id === item._id);

        if (isProcessInProcessesToAddMaterialsList) {
          return {
            ...item,
            materials: action.payload.syncMaterials
          }
        } else {
          return item;
        }
      });

      return {
        ...state,
        processes: processesSyncMaterials
      }
    }
    case processesAndMaterialsEventTypes.onSelectAllProcessesToAddMaterials: {
      const processesSyncMaterials = state.processes.map((item: $TSFixMe) => {
        const isProcessInProcessesToAddMaterialsList = action.payload.processesToAddMaterials.find((process: $TSFixMe) => process._id === item._id);

        if (isProcessInProcessesToAddMaterialsList) {
          return {
            ...item,
            materials: action.payload.syncMaterials
          }
        } else {
          return item;
        }
      });

      return {
        ...state,
        processesToAddMaterials: action.payload.processesToAddMaterials,
        processes: processesSyncMaterials
      }
    }
    case processesAndMaterialsEventTypes.onResetState:
      return initialStore;
    default:
      return state;
  }
}

export { initialStore }
export { processesAndMaterialsEventTypes }
export default processesAndMaterialsStoreReducer;