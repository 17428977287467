import { createContext, useContext, useReducer } from 'react'
import processesAndMaterialsStoreReducer, { initialStore } from './ProcessesAndMaterialsStoreReducer';

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const ProcessesAndMaterialsStoreContext = createContext();

const ProcessesAndMaterialsStoreProvider = ({
    children
}: $TSFixMe) => {
    const [store, dispatch] = useReducer(processesAndMaterialsStoreReducer, initialStore);

    return (
        <ProcessesAndMaterialsStoreContext.Provider value={[store, dispatch]}>
            {children}
        </ProcessesAndMaterialsStoreContext.Provider>
    )
}

export { ProcessesAndMaterialsStoreContext }

export const useProcessesAndMaterialsStore = () => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const store = useContext(ProcessesAndMaterialsStoreContext)[0];
  return store;
};

export const useProcessesAndMaterialsDispatch = () => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const dispatch = useContext(ProcessesAndMaterialsStoreContext)[1];
  return dispatch;
};

export default ProcessesAndMaterialsStoreProvider;