import { gql } from '@apollo/client';

export const GET_USER = gql`
query userGetUser($where: WhereUserInput!) {
  user(where: $where) {
    _id
    firstname
    lastname
    email
    isConfirmed
    registeredProfile
    mainProfile
    profiles
    isSuspended
    profile {
      profileName
      profileImage {
        src 
        width
        height
        type
        blurDataURL
      }
      occupation
      company
      description
      location {
        postalCode
        state
        municipality
      }
      profileBackgroundImage {
        src 
        width
        height
        type
        blurDataURL
      }
      contactLinks {
        facebook
        instagram
        linkedin
        twitter
        web
      }
      addresses {
        _id
        street
        suburb
        state
        fullname
        municipality
        outdoorNumber
        indoorNumber
        postalCode
        reference
        contactNumber
      }
    }
    phoneNumber
    cellphoneNumber
    rfc
    tours {
      profile
      studio
      portfolio
      factory
      products
    }
  }
}
`;

export const GET_USER_ADDRESS = gql`
query getUser($where: WhereUserInput!) {
  user(where: $where) {    
    profile {     
      addresses {
        _id
        street
        suburb
        state
        fullname
        municipality
        outdoorNumber
        indoorNumber
        postalCode
        reference
        contactNumber
      }
    }
  }
}
`;