const profileEventTypes = {
    onSelectProfile: 'select_profile'
}


const initialStore = {
    profile: '',
}

const profileStoreReducer = (state: $TSFixMe, action: $TSFixMe) => {
    switch (action.type) {
        case profileEventTypes.onSelectProfile: 
            return {
                ...state,
                profile: action.payload
            }
        default:
            return state;
    }
}

export {initialStore}
export {profileEventTypes}
export default profileStoreReducer;