import React from 'react';
import styles from './styles/Snackbar.module.css';
import cn from 'classnames';

type SnackbarProps = {
    variant: "success" | "error" | "info"
    message: string
}

const Snackbar = React.forwardRef(({ variant, message }: SnackbarProps, ref) => {
    return (
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        <div ref={ref} className={
            //classnames como cn, me permite condicionar multiples class de css y elegir una dependiendo la condicion.
            cn({
                [styles.successSnackbar]: variant === 'success',
                [styles.errorSnackbar]: variant === 'error',
                [styles.infoSnackbar]: variant === 'info'
            })
        }>
            <span>{message}</span>
        </div>
    )
});

Snackbar.displayName = 'Snackbar';

export default Snackbar;