import { useMemo } from 'react';
import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'apol... Remove this comment to see the full error message
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from "@apollo/client/link/context";
import { getUserAuthToken } from '../helpers/getUserAuthToken';

let apolloClient: $TSFixMe

const httpLink = createUploadLink({
  uri: `${process.env.NEXT_PUBLIC_APOLLO_SERVER_URI}`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

//Gets profile type from local storage and sets in headers to send to the apollo server
const authProfileMiddleware = new ApolloLink((operation, forward) => {
  // add the profile to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      profile: typeof window === 'undefined' ? null : localStorage.getItem('profile'),
    }
  }));

  return forward(operation);
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.getContext().hasUpload) {
    const omitTypename = (key: $TSFixMe, value: $TSFixMe) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const asyncAuthLink = (authToken: $TSFixMe) => {
  const link = setContext(
    request =>
      new Promise(async (resolve) => {
        // do some async lookup here
        const token = typeof window === 'undefined' ? authToken : await getUserAuthToken();
        if (token) {
          resolve({
            headers: { Authorization: token }
          });
        } else {
          resolve({});
        }
      })
  );

  return link;
};

function createApolloClient(authToken: $TSFixMe) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache({
      typePolicies: {
        ProcessWithMaterials: {
          fields: {
            materials: {
              merge(existing = [], incoming) {
                return [...incoming]
              }
            }
          }
        },
        Query: {
          fields: {
            projects: relayStylePagination(),
            orders: relayStylePagination(),
            availableProvidersByOrder: relayStylePagination(),
            connectionRequestsSentByClient: relayStylePagination(),
            connectionRequestsSentToClient: relayStylePagination(),
            connectionRequestsSentByProvider: relayStylePagination(),
            connectionRequestsSentToProvider: relayStylePagination(),
            connectionRequestsOrder: relayStylePagination(),
            connectionRequestsProvider: relayStylePagination(),
          }
        }
      }
    }),
    link: from([cleanTypeName, asyncAuthLink(authToken), authProfileMiddleware, errorLink, httpLink])
  })
}

export function initializeApollo(initialState = null, authToken = null) {
  const _apolloClient = apolloClient ?? createApolloClient(authToken);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState: $TSFixMe) {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
