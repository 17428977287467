import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation CreateProject($data: ProjectCreateInput!) {
    createProject(data: $data) {
      project {
        _id
      }
      component {
        _id
        name
        type
        media {
          models {
            type
            filename
            src
          }
          images {
            _id
            src
            width
            height
            type
            blurDataURL
          }
          manufactoryPlans {
            filename
            src
            type
          }
        }
        notes
        materials
        measures
        numberPieces
        hasSample
        sampleQuantity
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($data: ProjectUpdateInput!, $projectId: ID!) {
    updateProject(data: $data, projectId: $projectId) {
      _id
      status
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId) {
      _id
    }
  }
`;

export const DISABLE_NOTIFICATION_PROJECT = gql`
mutation DisableNotificationProject($projectId: ID!) {
  disableNotificationProject(projectId: $projectId) {
    _id
  }
}
`;