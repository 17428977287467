export const translateProjectComplexity = (complexity: $TSFixMe) => {
    let complexityTranslated;

    switch (complexity) {
        case 'RIGID':
            complexityTranslated = '1'
            break;
        case 'MECHANICAL':
            complexityTranslated = '2'
            break;
        case 'MECHATRONIC':
            complexityTranslated = '3'
            break;
        default:
            complexityTranslated = 'Error...'
            break;
    }

    return complexityTranslated;
}

export const translateProjectStatus = (status: $TSFixMe) => {
    let statusTranslated;

    switch (status) {
        case 'IN_PROGRESS':
            statusTranslated = 'En proceso'
            break;
        case 'COMPLETED':
            statusTranslated = 'Completado'
            break;
        case 'IN_CONNECTION':
            statusTranslated = 'En conexión'
            break;
        case 'NO_STARTED':
            statusTranslated = 'No comenzado'
            break;
        case 'DRAFT':
            statusTranslated = 'Borrador'
            break;
        case 'IN_REVIEW':
            statusTranslated = 'En revisión'
            break;
        case 'PAUSED':
            statusTranslated = 'Pausado'
            break;
        case 'CANCELED':
            statusTranslated = 'Cancelado'
            break;
        default:
            statusTranslated = 'Error...'
            break;
    }

    return statusTranslated;
}

export const statusProjectList = [
    { id: 'DRAFT', label: 'Borrador' },
    { id: 'IN_CONNECTION', label: 'En conexión' },
    { id: 'IN_PROGRESS', label: 'En proceso' },
    { id: 'COMPLETED', label: 'Completado' },
]

export const statusOrdersList = [
    { id: 'DRAFT', label: 'Borrador' },
    { id: 'IN_CONNECTION', label: 'En conexión' },
    { id: 'IN_PROGRESS', label: 'En proceso' },
    { id: 'COMPLETED', label: 'Completado' },
    { id: 'PAUSED', label: 'Pausado' },
    { id: 'IN_REVIEW', label: 'Revisión' },
    { id: 'CANCELED', label: 'Cancelado' }
]

export const OrderStatusEnum = {
    Draft: 'DRAFT',
    InConnection: 'IN_CONNECTION',
    InProgress: 'IN_PROGRESS',
    Completed: 'COMPLETED',
    Paused: 'PAUSED',
    InReview: 'IN_REVIEW',
    Canceled: 'CANCELED',
}

export const OrderCaseEnum = {
    InStock: 'IN_STOCK',
    OnDemand: 'ON_DEMAND'
}

export const getProjectLevelAttr = (attrSearch: $TSFixMe, returnAttr: $TSFixMe) => {
    const result = levelsList.find((item) => item.key === attrSearch);
    if (result && returnAttr) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return result[returnAttr];
    }

    return result;
};

export const getProjectComplexityAttr = (attrSearch: $TSFixMe, returnAttr: $TSFixMe) => {
    const result = complexitiesList.find((item) => item.key === attrSearch);
    if (result && returnAttr) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return result[returnAttr];
    }

    return result;
};

export const phasesList = [
    // {
    //   title: "Desarrollo",
    //   description: "Conecta con CREADORES",
    //   backgroundImage:
    //     "https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/development.jpg",
    //   alt: "development",
    //   key: "DEVELOPMENT",
    // },
    {
        title: "Fabricación",
        description: "Conecta con FABRICANTES",
        backgroundImage:
            "https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/manufacturer.jpg",
        alt: "manufactory",
        key: "MANUFACTORY",
    },
];

export const levelsList = [
    {
        key: 'CUSTOMIZE',
        content: 'HACER O PERSONALIZAR UN PRODUCTO EXISTENTE EN EL MERCADO',
        backgroundImage: 'https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/i1.jpg',
        alt: 'Producto',
        type: 'CUSTOMIZE',
        code: '1'
    },
    {
        key: 'NEW_VERSION',
        content: 'HACER LA SIGUIENTE VERSIÓN DE UN PRODUCTO EXISTENTE EN EL MERCADO',
        backgroundImage: 'https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/i2.jpg',
        alt: 'Silla minimalista',
        type: 'NEW_VERSION',
        code: '2'
    },
    {
        key: 'NEW',
        content: 'CREAR UN PRODUCTO QUE AÚN NO EXISTE EN EL MERCADO',
        backgroundImage: 'https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/i3.jpg',
        alt: 'Humo naranja',
        type: 'NEW',
        code: '3'
    }
]

export const complexitiesList = [
    {
        key: 'RIGID',
        content: 'MI PRODUCTO NO INCLUYE PARTES MOVIBLES',
        backgroundImage: 'https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/c1.png',
        alt: 'Dinosaurio de madera',
        type: 'RIGID',
        code: '1'
    },
    {
        key: 'MECHANICAL',
        content: 'MI PRODUCTO INCLUYE PARTES MOVIBLES',
        backgroundImage: 'https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/c2.jpg',
        alt: 'Maniqui de madera movible',
        type: 'MECHANICAL',
        code: '2'
    },
    {
        key: 'MECHATRONIC',
        content: 'MI PRODUCTO INCLUYE PARTES MOVIBLES Y COMPONENTES ELECTRÓNICOS',
        backgroundImage: 'https://r3d-almacenamiento.s3.us-east-2.amazonaws.com/about_r3d/assets/c3.jpg',
        alt: 'Drone',
        type: 'MECHATRONIC',
        code: '3'
    }
]

export const measureInputs = [
    {
        label: 'Largo',
        defaultUnit: 'cm',
        field: 'large',
        unitOptions: [
            { id: 'mm', label: 'MM' },
            { id: 'cm', label: 'CM' },
            { id: 'm', label: 'M' }
        ]
    },
    {
        label: 'Ancho',
        defaultUnit: 'cm',
        field: 'width',
        unitOptions: [
            { id: 'mm', label: 'MM' },
            { id: 'cm', label: 'CM' },
            { id: 'm', label: 'M' }
        ]
    },
    {
        label: 'Alto',
        defaultUnit: 'cm',
        field: 'height',
        unitOptions: [
            { id: 'mm', label: 'MM' },
            { id: 'cm', label: 'CM' },
            { id: 'm', label: 'M' }
        ]
    },
    {
        label: 'Peso',
        defaultUnit: 'kg',
        field: 'weight',
        unitOptions: [
            { id: 'kg', label: 'KG' },
            { id: 'g', label: 'G' },
            { id: 'mg', label: 'MG' }
        ]
    }
];

export const parseMeasures = (measures: $TSFixMe) => {
    let tempObject = { ...measures };

    for (const key of Object.keys(tempObject)) {
        if (tempObject[key] && tempObject[key].measure === null) {
            tempObject[key] = null;
        }
    }

    return tempObject;
}
