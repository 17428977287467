import { useSession } from 'next-auth/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { createContext, useContext, useReducer } from 'react'
import profileStoreReducer, { initialStore, profileEventTypes } from './profileStoreReducer';
import { useQuery } from '@apollo/client';
import { GET_USER } from '../../apollo/api/user/queries';
import LoadingFeedback from '../../components/LoadingComponent/LoadingFeedback';
import { Stack } from '@mui/material';

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const ProfileStoreContext = createContext();

const notRequiredProfilePaths = [
    '/new_profile',
    '/my_projects/new_project/[id]',
    '/my_projects/order/[id]',
    '/confirmation_account',
    '/sign_up/confirmated_email'
];

export const ProfileEnum = {
    Fabricante: "FABRICANTE",
    Mir3d: "MIR3D"
}

const ProfileStoreProvider = ({
    children
}: $TSFixMe) => {
    const [store, dispatch] = useReducer(profileStoreReducer, initialStore);
    const [session, loading] = useSession();
    const router = useRouter();

    const { data, error, loading: loadingUser } = useQuery(GET_USER, {
        variables: {
            where: {
                // @ts-expect-error TS(2339): Property 'userId' does not exist on type '{ name?:... Remove this comment to see the full error message
                id: session?.user?.userId
            }
        },
        // @ts-expect-error TS(2339): Property 'userId' does not exist on type '{ name?:... Remove this comment to see the full error message
        skip: loading || !session?.user?.userId
    });

    useEffect(() => {
        const profileValidator = () => {
            if (!loading && session && session.user) {
                const profile = localStorage.getItem('profile');

                //Verifying if the current user has a registered profile
                // @ts-expect-error TS(2339): Property 'registeredProfile' does not exist on typ... Remove this comment to see the full error message
                if (!session.user.registeredProfile) {

                    // @ts-expect-error TS(2339): Property 'mainProfile' does not exist on type '{ n... Remove this comment to see the full error message
                    if (session.user.mainProfile) {
                        dispatch({
                            type: profileEventTypes.onSelectProfile,
                            // @ts-expect-error TS(2339): Property 'mainProfile' does not exist on type '{ n... Remove this comment to see the full error message
                            payload: session.user.mainProfile,
                        });
                    }

                    if (!notRequiredProfilePaths.includes(router.pathname)) {
                        router.push('/new_profile');
                    }

                    return;
                }

                //The current user has a registered profile, but doesnt hava a profile in the localstorage
                if (!profile) {
                    // @ts-expect-error TS(2339): Property 'mainProfile' does not exist on type '{ n... Remove this comment to see the full error message
                    localStorage.setItem('profile', session.user.mainProfile);

                    dispatch({
                        type: profileEventTypes.onSelectProfile,
                        // @ts-expect-error TS(2339): Property 'mainProfile' does not exist on type '{ n... Remove this comment to see the full error message
                        payload: session.user.mainProfile,
                    });

                    return;
                }

                //If the user session has the profile in profiles then add the profile to the profile context
                // @ts-expect-error TS(2339): Property 'profiles' does not exist on type '{ name... Remove this comment to see the full error message
                if (!session.user.profiles?.includes(profile)) {
                    localStorage.removeItem('profile');
                    router.push('/');

                    return;
                }

                dispatch({
                    type: profileEventTypes.onSelectProfile,
                    payload: profile,
                });
            }
        }

        profileValidator();
    }, [loading, session, router]);

    if (loading || loadingUser) {
        return (
            <Stack width='100%' height='100vh' sx={{ background: 'rgba( 255, 255, 255, 0.25 )', backdropFilter: 4, WebkitBackdropFilter: 4 }} boxShadow={'0 8px 32px 0 rgba( 31, 38, 135, 0.37 )'} >
                <LoadingFeedback />
            </Stack>
        )
    }

    return (
        <ProfileStoreContext.Provider value={{
            store,
            dispatch,
            dataUser: data?.user,
            dtaUserError: error,
            dataUserLoading: loadingUser
        }}>
            {children}
        </ProfileStoreContext.Provider>
    )
}

export { ProfileStoreContext }

export const useProfileProvider = () => {
    const profileContext = useContext(ProfileStoreContext);
    return profileContext
}

export default ProfileStoreProvider;
