import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { createContext, useCallback, useContext, useReducer } from 'react'
import { CREATE_PROJECT, UPDATE_PROJECT } from '../../apollo/api/project/mutations';
import Snackbar from '../../components/SnackbarComponent/Snackbar';
import projectStoreReducer, { initialStore, projectEventsType } from './ProjectStoreReducer';
import { GET_ORDER_BY_PROJECT } from '../../apollo/api/order/queries';
import { parseMeasures } from '../../utils/data/project';
import { getDateObjectFromISOString } from '@/lib/utils';

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const ProjectStoreContext = createContext();

const ProjectStoreProvider = ({
  children
}: $TSFixMe) => {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [store, dispatch] = useReducer(projectStoreReducer, initialStore);
  const [isLoadingUpdateProject, setIsLoadingUpdateProject] = useState(false);

  const queryParams = {
    published: "true",
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const { data: orderData } = useQuery(GET_ORDER_BY_PROJECT, {
    variables: {
      projectId: store._id
    },
    skip: !store._id,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (orderData && orderData.orderByProject) {
      if (router.route === '/my_projects/edit_project/[id]') {
        if (orderData.orderByProject.status !== "DRAFT") {
          //Redirecting the project if not in status DRAFT
          router.push(`/my_projects/order/${store._id}?${queryString}`);
        } else {
          if (!store.orderId) {
            //Setting the project data when editing
            const projectData = { ...orderData.orderByProject.project } as any; //As any to add other props to the projectData object
            projectData.information = {
              ...orderData.orderByProject.project.information,
              category: { label: orderData.orderByProject.project.information.category.category, id: orderData.orderByProject.project.information.category._id },
              startDate: orderData.orderByProject.startDate ? getDateObjectFromISOString(orderData.orderByProject.startDate) : null,
              limitDate: orderData.orderByProject.limitDate ? getDateObjectFromISOString(orderData.orderByProject.limitDate) : null,
              startAmount: orderData.orderByProject.startAmount,
              baseBudget: orderData.orderByProject.baseBudget,
              limitBudget: orderData.orderByProject.limitBudget,
              maxAmount: orderData.orderByProject.maxAmount,
              currency: { label: orderData.orderByProject.currency.toUpperCase(), id: orderData.orderByProject.currency },
            };
            projectData.development = orderData.orderByProject.development;
            projectData.manufactory = orderData.orderByProject.manufactory;

            delete projectData.information.__typename; //Removing this property before setting project data
            dispatch({ type: projectEventsType.onSetProject, payload: { project: projectData } });
          }
        }
      }

      dispatch({ type: projectEventsType.setOrderId, payload: { orderId: orderData.orderByProject._id } });
    }
  }, [orderData]);

  const [createProject, { loading: createProjectLoading }] = useMutation(CREATE_PROJECT);

  const [updateProject, { loading: updateProjectLoading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: (data) => {
      enqueueSnackbar('', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom'
        },
        // eslint-disable-next-line react/display-name
        content: () => {
          switch (data.updateProject.status) {
            case 'DRAFT':
              return (

                <Snackbar variant={'success'} message={'Proyecto guardado correctamente'} />
              );
            case 'IN_CONNECTION':
              return (

                <Snackbar variant={'success'} message={'Proyecto publicado correctamente'} />
              );
            default:
              break;
          }
        }
      });

      switch (data.updateProject.status) {
        case 'DRAFT':
          router.push('/my_projects');
          break;
        case 'IN_CONNECTION':
          router.push(`/my_projects/order/${data.updateProject._id}?${queryString}`);
          break;
        default:
          router.push('/my_projects');
          break;
      }
    },
    onError: (error) => {
      setIsLoadingUpdateProject(false);
      enqueueSnackbar('', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom'
        },
        // eslint-disable-next-line react/display-name
        content: () => {
          return (

            <Snackbar variant={'error'} message={error.message} />
          )
        }
      });
    }
  });

  const handleOnDraft = useCallback(async (data: $TSFixMe, afterComplete: $TSFixMe) => {
    if (store && !store._id) {
      createProject({
        variables: {
          data: {
            projectId: router.query.id,
            information: {
              ...data,
              category: {
                _id: data.category.id,
                category: data.category.label
              },
              currency: data.currency.id,
              startDate: data.startDate,
              limitDate: data.limitDate
            }
          }
        },
        onCompleted: (data) => {
          enqueueSnackbar('', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom'
            },
            // eslint-disable-next-line react/display-name
            content: () => {
              return (

                <Snackbar variant={'info'} message={'Proyecto guardado en borradores'} />
              )
            }
          });

          dispatch({ type: projectEventsType.onSetProjectId, payload: { _id: data.createProject.project._id } });

          if (data.createProject.component) {
            dispatch({ type: projectEventsType.onAddComponent, payload: data.createProject.component });
          }

          afterComplete();
        },
        onError: (error) => {
          enqueueSnackbar('', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom'
            },
            // eslint-disable-next-line react/display-name
            content: () => {
              return (

                <Snackbar variant={'error'} message={error.message} />
              )
            }
          });
        }
      });
    }
  }, [createProject, store, router.query.id]);

  const handleOnSubmit = useCallback((status: $TSFixMe) => {
    if (store) {
      setIsLoadingUpdateProject(true);
      updateProject({
        variables: {
          data: {
            status: status,
            information: {
              ...store.information,
              category: {
                _id: store.information.category.id,
                category: store.information.category.label
              },
              currency: store.information.currency.id,
            },
            development: null,
            manufactory: {
              features: {
                components: store.manufactory.features.components.map((item: $TSFixMe) => {
                  return {
                    _id: item._id,
                    notes: item.notes,
                    materials: item.materials,
                    measures: parseMeasures(item.measures),
                    numberPieces: item.numberPieces,
                    hasSample: item.hasSample,
                    sampleQuantity: item.sampleQuantity
                  }
                }),
                assemblies: store.manufactory.features.assemblies.map((item: $TSFixMe) => {
                  return {
                    _id: item._id,
                    notes: item.notes,
                    measures: parseMeasures(item.measures),
                    numberPieces: item.numberPieces
                  }
                }),
              }
            }
          },
          projectId: store._id
        }
      })
    }
  }, [store, updateProject]);

  /*Validates whether userData has the origin values or not */
  const validateUserData = () => {
    const emptyUserData = '{"minAge":null,"maxAge":null,"gender":null,"nationalities":[],"favoriteBrands":[],"keyRequirements":[]}';
    if (store.development && JSON.stringify(store.development.userData) !== emptyUserData) {
      return false;
    }

    return true;
  }

  /*Validates whether features has the origin values or not */
  const validateFeatures = () => {
    const emptyFeatures = '{"components":[],"assemblies":[],"hasAssemblies":false}';
    if (store.manufactory && store.manufactory.features && JSON.stringify(store.manufactory.features) !== emptyFeatures) {
      return false;
    }

    return true;
  }

  return (
    <ProjectStoreContext.Provider value={{
      store,
      dispatch,
      handleOnDraft,
      validateUserData,
      validateFeatures,
      handleOnSubmit,
      createProjectLoading,
      updateProjectLoading: isLoadingUpdateProject || updateProjectLoading,
    }}>
      {children}
    </ProjectStoreContext.Provider>
  )
}

export { ProjectStoreContext }

export const useProjectStore = () => {
  // @ts-expect-error TS(2339): Property 'store' does not exist on type 'unknown'.
  const { store, handleOnDraft, validateUserData, validateFeatures, handleOnSubmit, updateProjectLoading, createProjectLoading } = useContext(ProjectStoreContext);
  return { store, handleOnDraft, validateUserData, validateFeatures, handleOnSubmit, updateProjectLoading, createProjectLoading };
};

export const useProjectDispatch = () => {
  // @ts-expect-error TS(2339): Property 'dispatch' does not exist on type 'unknow... Remove this comment to see the full error message
  const { dispatch } = useContext(ProjectStoreContext);
  return dispatch;
};

export default ProjectStoreProvider;